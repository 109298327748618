import * as React from 'react';

import Layout from '../../components/homepage-layout.js';

const Page = () => (
  <Layout
    title="Web development"
    description="Tools for web development."
  >
    <div className="px-4 pb-10 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6">
      <div className="text-center">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block">Tools for web development</span>
        </h1>
        <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          This page is under construction.
        </p>
      </div>
    </div>
  </Layout>
);

export default Page;
